import React from "react";
import Layout from "../components/layout";

const Contact = () => {
  return (
    <Layout>
      <section
        className="hero is-medium is-primary is-bold"
        style={{ position: "relative" }}
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">Contact Us</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: "500px" }}>
          <h2 className="title">Hello!</h2>
          <p className="subtitle">
            I hope you have found this app to be really helpful.
          </p>
          <p className="subtitle">
            It's supposed to make training the relaxation protocol just a bit
            easier.
          </p>
          <p className="subtitle">
            I would love to hear your , ideas, and feedback on how I can make it
            better. Or just compliments 😉
          </p>
          <form
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <div className="field">
              <label htmlFor="name" className="label">
                Name
              </label>
              <div className="control">
                <input
                  id="name"
                  name="name"
                  className="input"
                  type="text"
                  placeholder="Your Name"
                />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="email">
                Email
              </label>
              <div className="control">
                <input
                  id="email"
                  name="email"
                  className="input"
                  type="email"
                  placeholder="Your Email"
                />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="message">
                Message
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  id="message"
                  name="message"
                  placeholder="Your Feedback"
                ></textarea>
              </div>
            </div>

            <button className="button is-primary is-rounded">Submit</button>
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
